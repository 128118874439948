"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractsForChainOrThrow = void 0;
const contracts_1 = require("@nounsbr/contracts");
const addresses_1 = require("./addresses");
/**
 * Get contract instances that target the Ethereum mainnet
 * or a supported testnet. Throws if there are no known contracts
 * deployed on the corresponding chain.
 * @param chainId The desired chain id
 * @param signerOrProvider The ethers v5 signer or provider
 */
const getContractsForChainOrThrow = (chainId, signerOrProvider) => {
    const addresses = (0, addresses_1.getContractAddressesForChainOrThrow)(chainId);
    return {
        nounsbrTokenContract: contracts_1.NounsBRTokenFactory.connect(addresses.nounsbrToken, signerOrProvider),
        nounsbrAuctionHouseContract: contracts_1.NounsBRAuctionHouseFactory.connect(addresses.nounsbrAuctionHouseProxy, signerOrProvider),
        nounsbrDescriptorContract: contracts_1.NounsBRDescriptorFactory.connect(addresses.nounsbrDescriptor, signerOrProvider),
        nounsbrSeederContract: contracts_1.NounsBRSeederFactory.connect(addresses.nounsbrSeeder, signerOrProvider),
        nounsbrDaoContract: contracts_1.NounsBRDaoLogicV1Factory.connect(addresses.nounsbrDAOProxy, signerOrProvider),
    };
};
exports.getContractsForChainOrThrow = getContractsForChainOrThrow;
